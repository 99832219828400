import { NgModule, PLATFORM_ID, inject } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { isPlatformServer } from '@angular/common';

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
	mutate: {
		fetchPolicy: 'no-cache',
	},
};

function resolveURI() {
	const platformId = inject(PLATFORM_ID);
	const domain = environment.graphql_url;
	const local = 'http://127.0.0.1/api/graphql';
	const baseUrl = isPlatformServer(platformId) && !environment.devMode ? local : domain;

	return baseUrl;
}

@NgModule({
	imports: [BrowserModule, ApolloModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink) => {
				const errorLink = onError(({ graphQLErrors, networkError, response }) => {
					if (graphQLErrors) {
						graphQLErrors.map(({ message, locations, path }) =>
							console.error(
								`[GRAPHQL ERROR]: Message: ${message}, Location: ${locations}, Path: ${path}`,
							),
						);
					}

					if (networkError) {
						const error = JSON.stringify((networkError as any)?.error);
						console.error(`[ERROR MESSAGE]: ${error}`);
					}

					if (response) {
						console.error(`[RESPONSE]: ${JSON.stringify(response)}`);
					}
				});

				return {
					cache: new InMemoryCache({ addTypename: false }),
					link: errorLink.concat(httpLink.create({ uri: resolveURI() })),
					defaultOptions: defaultOptions,
				};
			},
			deps: [HttpLink],
		},
	],
})
export class GraphQLModule {}
